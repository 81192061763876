import React, { useState } from 'react';
import axios from 'axios';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';

const ChatWithAurora = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioText, setAudioText] = useState(null);
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [limitMessage, setLimitMessage] = useState('');

  const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start();

        // Collect audio data as it comes
        const audioChunks = [];
        recorder.ondataavailable = event => {
          console.log(`Chunk size: ${event.data.size}`);
          audioChunks.push(event.data);
        };
        recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks);
            console.log('audioBlob: ',audioBlob);
            sendAudioToServer(audioBlob);
            console.log("Audio to text: ", audioText);
            //sendMessage(audioText);
        };

        setIsRecording(true);
    } catch (error) {
        console.error('Error accessing the microphone', error);
    }
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    setIsRecording(false);
  };

  const sendAudioToServer = async (audioBlob) => {
    console.log('input audioBlob: ', audioBlob);
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audiofile.mp3');
    console.log('Form audioBlob: ',formData);
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    try {
        const response = await axios.post('/recognize',formData,
          {headers: {'Content-Type': 'multipart/form-data'}  // This is optional, as browsers fill this correctly
      });
        // const data = await response.json();
        console.log("respuesta de server.js: ", response);
        setAudioText(response.data.transcript);
        sendMessage(response.data.transcript);
        console.log("Audio to text: ", response.data.transcript);
    } catch (error) {
        console.error('Failed to send audio to server', error);
    }
  };

  async function playResponseAsAudio(text) {
    const speakData =JSON.stringify({ text: text });
    const speakHeaders = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      responseType: 'blob'  // This tells Axios to expect a binary response
};
    try {
        console.log('/speak call data: ', speakData);
        console.log('/speak call headers: ', speakHeaders);
        const response = await axios.post('/speak', 
                                          speakData, speakHeaders);
        console.log("Response from Speak endpoint from server.js: ",response)
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        // const data = await response.json();
        // playAudio(data.audioContent);
        playAudio(response.data);
    } catch (error) {
        console.error('Error fetching audio:', error);
    }
  }

  function playAudio(audioData) {
    console.log('audio data to play: ',audioData);
    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
    console.log('audio blob for URL: ',audioBlob);
    
    if (audioBlob.size > 0) {
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log('audio URL: ', audioUrl);
      const audio = new Audio(audioUrl);
      console.log('about to play: ', audio);
      audio.play().catch(e => console.error('Error playing audio:', e));
    } else {
      console.error('Received empty audio blob.');
    }
  }

  const sendMessage = async (inputText) => {
    console.log("inputText: ",inputText.value);
    if (!inputText) return;
    const newMessage = { text: inputText, sender: 'user' };
    setConversation([...conversation, newMessage]);

    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/chat`, { inputText });
      const response = await axios.post('/chat', { inputText });
      if (response.status === 429) { // Checking if the limit has been reached
        setLimitMessage("Thank you for chatting with Aurora today! You've reached your daily limit of ten conversations. We invite you to return tomorrow for more engaging and insightful chats. Have a great day!")
        return;
      }
      const reply = { text: response.data.reply, sender: 'Aurora' };
      playResponseAsAudio(reply.text);
      setConversation([...conversation, newMessage, reply]);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle the limit reached case
        setLimitMessage("Thank you for chatting with Aurora today! You've reached your daily limit of ten conversations. We invite you to return tomorrow for more engaging and insightful chats. Have a great day!");
      } else {
        // Handle other types of errors
        console.error('Error getting response and playing as audio:', error);
      }
    }

    setMessage('');
  };

  const SoundWave = () => {
    return (
        <div style={{ display: 'inline-block' }}>
            <div className="sound-wave"></div>
            <div className="sound-wave"></div>
            <div className="sound-wave"></div>
        </div>
    );
  };

  return (
    <div>
      <br></br>
      <h2>Chat with Aurora</h2>
      <div className="messages">
        {conversation.map((msg, index) => (
          <div key={index} className={`message ${msg.sender === 'Aurora' ? 'aurora' : 'user'}`}>
            {msg.text}
          </div>
        ))}
      </div>
      <div className={`${limitMessage ? 'extra-spacing' : ''}`}>
        {limitMessage && (
          <p>{limitMessage}</p>
        )}
      </div>
      <h5>Share Your Thoughts or Ask Anything</h5>
      <input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' ? sendMessage(message) : null}
      />
      <button className="axil-btn btn-fill-primary btn-normal" 
      onClick={() => sendMessage(message)}
      >
        Send</button>
      <div>
        <br></br>
        <h5>Prefer to talk?</h5>
        <button className="axil-btn btn-fill-primary btn-normal"
        onClick={isRecording ? stopRecording : startRecording} 
        style={{ backgroundColor: isRecording ? 'red' : 'grey', marginTop:"-10vh" }}
        >
          {isRecording ? <FaMicrophone /> : <FaMicrophoneSlash />}
          {isRecording ? <SoundWave /> : null} {" "}
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>
      </div>
    </div>
  );
};

export default ChatWithAurora;
